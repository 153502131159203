import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import components from 'components';
import { uxSelectors, uxOperations } from 'railfleet_state/ducks/ux';
import { maintenanceEventsOperations } from 'railfleet_state/ducks/maintenance_events';
import CreateCorrectiveEvent from './create/create_corrective_event';
import CreatePreventiveEvent from './create/create_preventive_event';
import EditCampaignEvent from './create/edit_campaign_event';
import CorrectiveDetail from './details/corrective_detail';
import PreventiveDetail from './details/preventive_detail';
import CloseCorrectiveEvent from './close/close_corrective_event';
import ClosePreventiveEvent from './close/close_preventive_event';
import CampaignDetail from './details/campaign_detail';
import CloseCampaignEvent from './close/close_campaign_event';
import StandaloneEventForm from '../../../railfleet/lib/compounds/forms/standaloneEventForm/StandaloneEventForm';
import withNavigate from '../../../railfleet/lib/components/app/withNavigate';
import { LAYOUT, withMultiDeviceLayout } from '../../../railfleet/lib/components/app/MultiDeviceLayout';

const { Modal, MODAL_CONSTANTS } = components.containers;

const EventHub = (props) => {
    const [_editMode, setEditMode] = useState(false);
    const [_pushInfos, setPushInfos] = useState(null);
    const asset_id = props.asset_id || (props.asset && props.asset.info.id);

    useEffect(() => {
        if (props.event_id) {
            props.fetchEventDetails(props.event_id);
        }
    }, [props.event_id]);

    useEffect(() => {
        if (props.push_infos && props.push_infos.extras && props.push_infos.extras.target === 'status_change') return;
        setPushInfos(props.push_infos);
        props.setPushInfos(null, 'event hub');
    }, []);

    useEffect(() => {
        if (props.params && props.params.action === 'edit') setEditMode(true);
        else setEditMode(false);
    }, [props.params]);

    const onCloseModal = () => {
        props.router.push(
            `/asset/${asset_id}/events`,
        );
    };
    let Widget = null;
    if (props.standalone) {
        Widget = StandaloneEventForm;
    } else if ((props.params && ['new', 'edit'].includes(props.params.action)) || props.action === 'edit') {
        if (props.params.type === 'corrective' || props.maintenance_type === 'corrective') Widget = CreateCorrectiveEvent;
        if (props.params.type === 'preventive' || props.maintenance_type === 'preventive') Widget = CreatePreventiveEvent;
        if (
            props.params.action === 'edit'
            && (props.params.type === 'campaign' || props.maintenance_type === 'campaign')
        ) {
            Widget = EditCampaignEvent;
        }
    } else if (props.params && props.params.action === 'view') {
        if (props.params.type === 'corrective' || props.maintenance_type === 'corrective') Widget = CorrectiveDetail;
        if (props.params.type === 'preventive' || props.maintenance_type === 'preventive') Widget = PreventiveDetail;
        if (props.params.type === 'campaign' || props.maintenance_type === 'campaign') Widget = CampaignDetail;
    } else if ((props.params && props.params.action === 'close') || props.action === 'close') {
        if (props.params.type === 'corrective' || props.maintenance_type === 'corrective') Widget = CloseCorrectiveEvent;
        if (props.params.type === 'preventive' || props.maintenance_type === 'preventive') Widget = ClosePreventiveEvent;
        if (props.params.type === 'campaign' || props.maintenance_type === 'campaign') Widget = CloseCampaignEvent;
    } else if (props.viewer && !props.event_id) Widget = CreateCorrectiveEvent;
    else if (props.viewer && props.event_id && props.maintenance_type === 'corrective') Widget = CorrectiveDetail;
    else if (props.viewer && props.event_id && (props.maintenance_type === 'preventive' || props.event_type === 'preventive')) Widget = PreventiveDetail;
    else if (props.viewer && props.event_id && props.maintenance_type === 'campaign') Widget = CampaignDetail;
    if (!Widget) return null;

    const cancelCallback = () => {
        if (_pushInfos && _pushInfos.from) {
            window.location.href = _pushInfos.from;
        } else if (_editMode) {
            props.router.push(
                `/asset/${asset_id}/events/${props.event_id}/view`,
            );
        } else {
            props.router.push(
                `/asset/${asset_id}/events`,
            );
        }
    };

    const onClickEdit = () => {
        props.router.push(
            `/asset/${asset_id}/events/${props.event_id}/edit`,
        );
    };

    const onClickClose = () => {
        props.router.push(
            `/asset/${asset_id}/events/${props.event_id}/close`,
        );
    };

    const loginlessData = props.loginless ? props : null;

    return (
        <Modal
            layout={
                props.device === LAYOUT.DESKTOP
                    ? MODAL_CONSTANTS.LAYOUT.NONE
                    : MODAL_CONSTANTS.LAYOUT.FULL
            }
            position={MODAL_CONSTANTS.POSITION.RIGHT}
            onClose={props.onCloseModal || onCloseModal}
            handleCloseInside={!(props.driverLogbook && !props.event_id)}
        >
            <Widget
                asset_id={asset_id}
                component_id={props.component_id}
                event_id={props.event_id}
                router={props.router}
                device={props.device}
                cancelCallback={props.cancelCallback || cancelCallback}
                onCloseModal={props.onCloseModal || onCloseModal}
                onClickEdit={props.onClickEdit || onClickEdit}
                onClickClose={props.onClickClose || onClickClose}
                onClickPrevious={props.onClickPrevious}
                createCallback={props.createCallback}
                closeCallback={props.closeCallback}
                params={props.params}
                readOnly={props.readOnly}
                maintenance_type={props.maintenance_type || props.event_type}
                driverLogbook={props.driverLogbook}
                defaultCustomFields={props.defaultCustomFields}
                interventionIdRedirect={props.interventionIdRedirect}
                push_infos={_pushInfos}
                loginless={props.loginless}
                {...loginlessData}
            />
        </Modal>
    );
};

const mapStateToProps = (state, props) => {
    if (props.loginless) return props.mapStateToProps(state, props);
    const intervention_id = props.item_id;
    const event_id = props.event_id || props.item_id;
    const maintenance_event = event_id ? state.maintenance_events[event_id] : null;
    const maintenance_type = maintenance_event && maintenance_event.maintenance_type;
    return ({
        intervention_id,
        event_id,
        maintenance_type,
        push_infos: uxSelectors.getPushInfos(state),
    });
};

const mapDispatchToProps = (dispatch, props) => {
    if (props.loginless) return props.mapDispatchToProps(dispatch, props);
    return ({
        setPushInfos: (push_infos, from = null) => dispatch(uxOperations.setPushInfos(push_infos, from)),
        fetchEventDetails: (event_id) => dispatch(
            maintenanceEventsOperations.fetchDetails(event_id),
        ),
    });
};

// Fichier principal qui permet d'afficher soit le détail d'un event, soit le form et dispatché en fonction du type
// campaign, corrective, preventive
export default withMultiDeviceLayout(withNavigate(connect(mapStateToProps, mapDispatchToProps)(EventHub)));
